import { pendingFunc } from "@src/features/pending/pending-slice";
import { useAppDispatch } from "@src/hooks/redux";
import { AppDispatch } from "@src/store";
import React, { DependencyList, useCallback, useEffect, useState } from "react";

type SetterFunc<T> = (value: T) => void;
type FetcherFunc<T> = () => Promise<T>;

export type UpdaterFunc = () => void;
// eslint-disable-next-line @typescript-eslint/no-empty-function
export const emptyUpdaterFunc: UpdaterFunc = () => {};

export const useDataFetcher = <T>(
    setter: SetterFunc<T>,
    fetcher: FetcherFunc<T>,
    dependencies: DependencyList = [],
    fetchWrapper: (dispatch: AppDispatch, func: () => Promise<T>) => Promise<T> = pendingFunc,
): UpdaterFunc => {
    const dispatch = useAppDispatch();
    const [updateValue, triggerUpdate] = useState(0);

    useEffect(() => {
        const fetchData = async () => {
            const data = await fetchWrapper(dispatch, fetcher);
            setter(data);
        };

        fetchData();

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [updateValue, dispatch, ...dependencies]);

    return useCallback(() => triggerUpdate(Date.now()), [triggerUpdate]);
};

export const useStateWithDataFetcher = <T>(
    defaultValue: T,
    fetcher: FetcherFunc<T>,
    dependencies: DependencyList = [],
    fetchWrapper: (dispatch: AppDispatch, func: () => Promise<T>) => Promise<T> = pendingFunc,
): [T, React.Dispatch<React.SetStateAction<T>>, UpdaterFunc] => {
    const [data, setData] = useState<T>(defaultValue);
    const dataFetcher = useDataFetcher(setData, fetcher, dependencies, fetchWrapper);
    return [data, setData, dataFetcher];
};
