import { Box, IconButton, Typography } from "@mui/material";
import Paper from "@mui/material/Paper";
import theme from "@src/components/theme/theme";
import { MaintenanceData, selectMaintenance } from "@src/features/maintanance/maintenance-slice";
import { useAppSelector } from "@src/hooks/redux";
import { formatDate } from "@src/i18n";
import { DateTime } from "luxon";
import React from "react";
import { useTranslation } from "react-i18next";

import ExcavatorIcon from "./Excavator";

const MaintenanceMenu = () => {
    const maintenanceState = useAppSelector<MaintenanceData>(selectMaintenance);
    const { t } = useTranslation();

    const [maintanceInfoVisible, setMaintanceInfoVisible] = React.useState<boolean>(false);

    let endDate: Date | null = null;
    if (maintenanceState.scheduled && maintenanceState.planned && maintenanceState.duration) {
        endDate = new Date(new Date(maintenanceState.planned).getTime() + maintenanceState.duration * 60000);
    }

    return maintenanceState.scheduled ? (
        <Box sx={{ flexGrow: 0, ml: 2 }}>
            <IconButton
                color="warning"
                hidden={!maintenanceState.active}
                name="maintenanceNotificationButton"
                onPointerEnter={() => setMaintanceInfoVisible(true)}
                onPointerLeave={() => setMaintanceInfoVisible(false)}
                sx={{
                    ":hover": {
                        backgroundColor: theme.palette.warning.light,
                    },
                    backgroundColor: theme.palette.warning.main,
                }}
            >
                <ExcavatorIcon
                    sx={{
                        fill: "white",
                    }}
                />
            </IconButton>
            <Paper
                elevation={20}
                hidden={!maintanceInfoVisible}
                sx={{
                    backgroundColor: "warning.main",
                    position: "absolute",
                    right: 40,
                    top: 80,
                }}
            >
                <Typography
                    sx={{
                        color: "white",
                        padding: 2,
                    }}
                    textAlign="center"
                    variant="h5"
                >
                    {t("component.maintenance.title")}
                </Typography>
                <Typography
                    sx={{
                        color: "white",
                        padding: 2,
                    }}
                    variant="body1"
                >
                    {t("component.maintenance.announcement", {
                        replace: {
                            date: formatDate(maintenanceState.planned),
                            end: formatDate(endDate, DateTime.TIME_SIMPLE),
                            start: formatDate(maintenanceState.planned, DateTime.TIME_SIMPLE),
                        },
                    })}
                </Typography>
            </Paper>
        </Box>
    ) : null;
};

export default MaintenanceMenu;
