import { Address } from "@src/api/addresses";
import { BusinessArea, BusinessAreaDTO } from "@src/api/business-areas";
import { PaymentMethodType } from "@src/api/documents";
import { SymfonyFields } from "@src/api/interfaces/Symfony";
import { ApiResponse } from "@src/api/types/ApiResponse";
import { CinnamonID } from "@src/api/types/CinnamonID";
import { CompanyType } from "@src/api/types/CompanyType";
import { DocumentType } from "@src/api/types/DocumentType";
import { TaxZone } from "@src/api/types/TaxZone";
import { ZTermDTO } from "@src/api/zterms";
import { CompanyCustomizationInputs } from "@src/components/forms/companies/CompanyEditForm";
import argsBuilder from "@src/utils/args-builder";
import { MINUTE } from "@src/utils/cache";
import { deleteRelatedItemsFromCacheStorage } from "@src/utils/cache-storage-api";
import { get, post, put } from "@src/utils/request";
import React from "react";

export const CompaniesContext = React.createContext<Company[]>([]);

export const fetchCompany = async (companyId: CinnamonID): Promise<LockedCompany | null> =>
    get<LockedCompany>(`/internalApi/companies/${companyId}`);

export const fetchCompanies = async (
    companyType?: DocumentType,
    sort?: string | undefined,
    page?: number,
    pageSize?: number,
    freeText?: string,
): Promise<ApiResponse<Company>> => {
    const args = argsBuilder({ companyType, freeText, page, pageSize, sort });
    return await get<ApiResponse<Company>>(`/internalApi/companies${args}`, { cache: true, ttl: 5 * MINUTE });
};

export const updateCompany = async (companyId: CinnamonID, body: CompanySubmitData): Promise<void> => {
    await put(`/internalApi/companies/${companyId}`, { body });
    await burstCompanyCaches();
};

export const createCompany = async (body: CompanySubmitData): Promise<Company> => {
    const res = await post<Company>("/internalApi/companies", { body });
    await burstCompanyCaches();
    return res;
};

export const fetchDebitors = async (
    sort?: string | undefined,
    page?: number,
    pageSize?: number,
    freeText?: string,
): Promise<ApiResponse<Company>> => await fetchCompanies("debitor", sort, page, pageSize, freeText);

export const fetchCreditors = async (
    sort?: string | undefined,
    page?: number,
    pageSize?: number,
    freeText?: string,
): Promise<ApiResponse<Company>> => await fetchCompanies("creditor", sort, page, pageSize, freeText);

export const burstCompanyCaches = async () => deleteRelatedItemsFromCacheStorage("/internalApi/companies");

export interface Company extends SymfonyFields, CompanyCustomizations {
    id: CinnamonID;
    name: string;
    searchName: string;
    businessAreas: CompanyBusinessAreas[];
    customerNo: string;
    companyType: CompanyType;
    vat: VatDTO | null;
    vatNo: string;
    taxZone: TaxZone;
    debitorAccount: string;
    creditorAccount: string;
    active: boolean;
    salesVat: VatDTO | null;
    currency: string;
    discountDays: number;
    discount: number;
    creditorDiscountDays: number;
    creditorDiscount: string;
    addresses: Address[];
    supplierNo: string;
    numId: number;
}

export interface CompanyBusinessAreas {
    businessArea: BusinessAreaDTO;
    businessSubAreas: BusinessAreaDTO[];
}

interface CompanyCustomizations {
    creditorZterm?: CinnamonID | ZTermDTO;
    debitorZterm?: CinnamonID | ZTermDTO;
}

export interface LockedCompany extends Company {
    accountingCreditor?: {
        active: boolean;
        companyType: CompanyType;
        creditor_account: string;
        customerNo: string;
        debitor_account: string;
        id: CinnamonID;
        name: string;
        searchName: string;
        supplierNo: string;
    };
    ae: number;
    debitorCommentsPM: string;
    creditorComments: string;
    debitorComments: string;
    grossInOffer: boolean;
    grossInOrder: boolean;
    remittenceEmail: string;
    paymentDays: number;
    creditorPaymentDays: number;
    discountComments: string;
    paymentMethod: PaymentMethodType;
    teamAccess: TeamAccess[];
    bookingTypes: BookingTypes[];
}

interface VatDTO {
    id: number;
    name: string;
    currentValue: number;
}

export interface TeamAccess {
    businessArea: BusinessArea;
    team: {
        id: CinnamonID;
        name: string;
    };
}

export interface CompanySubmitData extends CompanyCustomizationInputs {
    name: string;
    grossInOffer: boolean;
    grossInOrder: boolean;
    debitorComments: string;
    debitorCommentsPM: string;
    searchName: string;
    customerNo: string;
    supplierNo: string;
    companyType: CompanyType;
    active: boolean;
    currency: string;
    vat: CinnamonID;
    vatNo: string;
    taxZone: TaxZone;
    docsViaEmail: boolean;
    docsEmail: string;
    remittenceEmail: string;
    creditorComments: string;
    debitorAccount: string;
    creditorAccount: string;
    paymentDays: number;
    discountDays: number;
    discountComments: string;
    discount: number;
    creditorPaymentDays: number;
    creditorDiscountDays: number;
    creditorDiscount: string;
    paymentMethod: string;
    salesVat: CinnamonID;
    businessAreas: {
        businessArea: CinnamonID;
        businessSubAreas: CinnamonID[];
    }[];
    teamAccess: {
        businessArea: CinnamonID;
        teams: CinnamonID[];
    }[];
    accountingCreditor: CinnamonID | null;
    ae: number;
    bookingTypes: BookingTypes[];
}

/**
 * @deprecated Don't use CompanyResponse, use ApiResponse<Company> instead.
 */
export type CompanyResponse = ApiResponse<Company>;

export enum BookingTypes {
    TV = "tv",
    Print = "print",
    Radio = "radio",
    Online = "online",
    OOH = "ooh",
    Other = "other",
}
