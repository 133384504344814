import { AppBar, Box, Button, CssBaseline, Paper, Toolbar, Typography } from "@mui/material";
import logoUrl from "@src/assets/fd-logo.svg";
import DevMenu from "@src/components/dev/DevMenu";
import MaintenanceMenu from "@src/components/maintenance/MaintenanceMenu";
import Navigation from "@src/components/navigation/Navigation";
import NotificationMenu from "@src/components/notification-menu/NotificationMenu";
import PendingIndicator from "@src/components/pending-indicator/PendingIndicator";
import StagePreviewBar from "@src/components/stage-preview/StagePreviewBar";
import IfVendor from "@src/components/theme/IfVendor";
import UserMenu from "@src/components/user-menu/UserMenu";
import { DEFAULT_APP_NAME } from "@src/constants";
import Vendor from "@src/features/customization/Vendor";
import { getNavigationOpenStatus, NavigationBarState } from "@src/features/navigation-bar/navigation-bar-slice";
import { useAppSelector } from "@src/hooks/redux";
import { formatDate } from "@src/i18n";
import { DateTime } from "luxon";
import React, { FC, ReactNode } from "react";
import { useNavigate } from "react-router-dom";

interface LayoutPropsInterface {
    children: ReactNode;
}

const appName = CINNAMON_APPNAME ?? DEFAULT_APP_NAME;

const DefaultLogoBox: FC = () => (
    <>
        <Box
            alt={appName}
            component="img"
            src={logoUrl}
            sx={{ height: 38, paddingRight: "12px" }}
        />
        <Typography
            component="div"
            noWrap
            sx={{ color: "white" }}
            variant="h6"
        >
            {appName}
        </Typography>
    </>
);

const Layout: FC<LayoutPropsInterface> = ({ children }) => {
    const { navigationOpen } = useAppSelector<NavigationBarState>(getNavigationOpenStatus);
    const navigate = useNavigate();

    return (
        <>
            <StagePreviewBar />
            <Box
                sx={{
                    display: "flex",
                    mt: CINNAMON_IS_PREVIEW || CINNAMON_IS_STAGE ? "20px" : 0,
                }}
            >
                <CssBaseline />

                <AppBar
                    position="fixed"
                    sx={{
                        mt: CINNAMON_IS_PREVIEW || CINNAMON_IS_STAGE ? "20px" : 0,
                        zIndex: theme => theme.zIndex.drawer + 1,
                    }}
                >
                    <Toolbar
                        sx={{
                            left: "-4px",
                        }}
                    >
                        <Button
                            onClick={() => navigate("/dashboard")}
                            sx={{
                                backgroundColor: "transparent",
                                p: 0,
                            }}
                        >
                            <IfVendor vendor={Vendor.Default}>
                                <DefaultLogoBox />
                            </IfVendor>
                            <IfVendor vendor={Vendor.DC}>
                                <DefaultLogoBox />
                            </IfVendor>
                            <IfVendor vendor={Vendor.A1}>
                                <Typography
                                    component="div"
                                    noWrap
                                    variant="h6"
                                >
                                    {"A1"}
                                </Typography>
                            </IfVendor>
                        </Button>

                        <Box sx={{ flexGrow: 1 }}>{/* Spacer */}</Box>

                        {/* GlobalSearch component disabled until further improvements ---- <GlobalSearch />*/}
                        <DevMenu />
                        <MaintenanceMenu />
                        <NotificationMenu />
                        <UserMenu />
                    </Toolbar>
                </AppBar>

                <Navigation navigationOpen={navigationOpen} />
                <Paper
                    sx={{
                        backgroundColor: "rgba(238, 238, 238, 255)",
                        bottom: 0,
                        left: 0,
                        padding: 0.35,
                        position: "fixed",
                        zIndex: 10000,
                    }}
                >
                    <Typography>
                        {`${CINNAMON_GITLAB_COMMIT_SHA.slice(0, 8)} | ${formatDate(
                            new Date(CINNAMON_BUILD_TIME),
                            DateTime.DATETIME_MED_WITH_SECONDS,
                        )}`}
                    </Typography>
                </Paper>

                <React.Suspense fallback={<PendingIndicator open />}>
                    <Box
                        component="main"
                        sx={{ flexGrow: 1, p: 3 }}
                    >
                        <Toolbar />
                        {children}
                    </Box>
                </React.Suspense>
            </Box>
        </>
    );
};
export default React.memo(Layout);
