import { CinnamonID } from "@src/api/types/CinnamonID";
import { Payment } from "@src/types/Payment";
import { deleteRelatedItemsFromCacheStorage } from "@src/utils/cache-storage-api";
import { get } from "@src/utils/request";

export const fetchPayment = async (id: CinnamonID): Promise<Payment> => {
    return get(`internalApi/payments/${id}`);
};

export const burstPaymentCaches = async () => {
    return deleteRelatedItemsFromCacheStorage("/internalApi/payments");
};
