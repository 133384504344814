import { Link, SxProps, Theme } from "@mui/material";
import { Company } from "@src/api/companies";
import { CreditorDocumentType, DebitorDocumentType } from "@src/api/documents";
import { CinnamonID } from "@src/api/types/CinnamonID";
import React from "react";

export enum DocumentType {
    JOB = "job",
    DEBITOR_DOC = "debitorDoc",
    CREDITOR_DOC = "creditorDoc",
    CREDITOR = "creditor",
    DEBITOR = "debitor",
}

interface DebitorDocumentLinkProps {
    children?: string | JSX.Element | JSX.Element[] | "() => JSX.Element";
    document: {
        id: CinnamonID;
        companyId?: CinnamonID;
        company?: Company;
        type: DebitorDocumentType;
    };
    title?: string;
    sx?: SxProps<Theme> | undefined;
}

interface CreditorsDebitorsEditProps {
    children?: string | JSX.Element | JSX.Element[] | "() => JSX.Element";
    document: {
        id: CinnamonID;
        companyId: CinnamonID;
        company: Company;
        type: CreditorDocumentType;
    };
    title?: string;
    sx?: SxProps<Theme> | undefined;
}

interface DocumentLinkProps {
    children?: string | JSX.Element | JSX.Element[] | "() => JSX.Element";
    id: CinnamonID;
    title?: string;
    documentType: DocumentType;
    companyId?: CinnamonID;
    documentSubType?: CreditorDocumentType | DebitorDocumentType;
    sx?: SxProps<Theme> | undefined;
}

/**
 * Shorthand for a DocumentLink to a debitor document.
 */
const DebitorDocumentLink: React.FC<DebitorDocumentLinkProps> = ({ children, document, title, sx }) => {
    return (
        <DocumentLink
            companyId={document.company ? document.company.id : document.companyId}
            documentSubType={document.type}
            documentType={DocumentType.DEBITOR_DOC}
            id={document.id}
            sx={sx}
            title={title}
        >
            {children || title}
        </DocumentLink>
    );
};

/**
 * Shorthand for a DocumentLink to a creditor document.
 */
const CreditorDocumentLink: React.FC<CreditorsDebitorsEditProps> = ({ children, document, title, sx }) => {
    return (
        <DocumentLink
            companyId={document.company ? document.company.id : document.companyId}
            documentSubType={document.type}
            documentType={DocumentType.CREDITOR_DOC}
            id={document.id}
            sx={sx}
            title={title}
        >
            {children || title}
        </DocumentLink>
    );
};

/**
 * A link to a document. The link is generated based on the document type. The type of link can be specified
 * with the variant property.
 */
const DocumentLink: React.FC<DocumentLinkProps> = ({
    children,
    id,
    title,
    documentType,
    companyId,
    documentSubType,
    sx,
}) => {
    let navLink = "";

    switch (documentType) {
        case DocumentType.DEBITOR: {
            navLink = `/debitors/list/edit/${id}`;
            break;
        }
        case DocumentType.CREDITOR: {
            navLink = `/creditors/list/edit/${id}`;
            break;
        }
        case DocumentType.DEBITOR_DOC: {
            navLink = `/debitors/document/${companyId}/${documentSubType}/${id}`;
            break;
        }
        case DocumentType.CREDITOR_DOC: {
            navLink = `/creditors/document/${companyId}/${documentSubType}/${id}`;
            break;
        }
        case DocumentType.JOB: {
            navLink = `/jobs/list/${id}/edit`;
            break;
        }
    }

    return (
        <Link
            href={navLink}
            sx={{
                ...sx,
            }}
            title={title}
            underline={"always"}
        >
            {children || title}
        </Link>
    );
};

export { DebitorDocumentLink, CreditorDocumentLink };

export default DocumentLink;
