/**
 * This file contains constants used throughout the application. It is recommended to use this file
 * instead of hardcoding values in the code. This will make it easier to change values in the future.
 * Sometimes it is more appropriate to use a theme value instead of a constant. That's the case when the value
 * shall change with a specific theme. e.g. there shall be something different in the dark mode compared to
 * the light mode.
 */
import { GridRenderCellParams, GridToolbar } from "@mui/x-data-grid-premium";
import theme from "@src/components/theme/theme";

export const DEFAULT_APP_NAME = "Finance Desk";

export const DataGridRowHeight = 52 * (2 / 3);
export const DataGridDoubleRowBreakpoint = 52;

/**
 * Used in the data grid to determine the minimum width of a row. Especially useful for columns containing
 * a StackGridElement which can be either a single row or a double row.
 */
export const DataGridMinRowWidthDecider = (singleRowWidth: number, doubleRowWidth: number): number => {
    return DataGridRowHeight >= DataGridDoubleRowBreakpoint ? singleRowWidth : doubleRowWidth;
};

export const DefaultDataGridPageSize = 20;
export const DataGridFooterRowHeight = 50;

// Configuration for how the data grids should display the control options for a row
export enum DataGridControlsStyle {
    Menu, // DataGrids use a menu to display user actions
    Toolicons, // DataGrids use icons in the data row to display user actions
}

export const DataGridMenuStyle: DataGridControlsStyle = DataGridControlsStyle.Toolicons;

// Configuration for how the data grids should display the selection checkmark
export enum DataGridSelectionCheckmarkPositions {
    Left, // DataGrids display the selection checkmark on the left
    Right, // DataGrids display the selection checkmark on the right
}

export const DataGridSelectionCheckmarkPosition: DataGridSelectionCheckmarkPositions =
    DataGridSelectionCheckmarkPositions.Left;

// Configuration for how the data grids column menu should display the selection checkmark
const dataGridColumnsPanelMenuStyles = {
    "& .MuiSwitch-root": {
        "& .MuiSwitch-thumb": {
            boxShadow: "none",
            height: 12,
            margin: 0.3,
            width: 12,
        },
        "& .MuiSwitch-track": {
            "&::after": {
                backgroundImage: `url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" height="12" width="12" viewBox="0 0 24 24"><path fill="${encodeURIComponent(
                    theme.palette.getContrastText(theme.palette.primary.main),
                )}" d="M19,13H5V11H19V13Z" /></svg>')`,
                right: 8,
            },
            "&::before": {
                backgroundImage: `url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" height="12" width="12" viewBox="0 0 24 24"><path fill="${encodeURIComponent(
                    theme.palette.getContrastText(theme.palette.primary.main),
                )}" d="M21,7L9,19L3.5,13.5L4.91,12.09L9,16.17L19.59,5.59L21,7Z"/></svg>')`,
                left: 8,
            },
            "&::before, &::after": {
                content: "\"\"",
                height: 12,
                position: "absolute",
                top: "50%",
                transform: "translateY(-50%)",
                width: 12,
            },
            borderRadius: 22 / 2,
        },
        padding: 0.5,
    },
};

/**
 * Configuration for the DataGridPremium instances. If a DataGrid needs to be configured differently, it can be
 * done in the specific component. By default, every DataGridPremium instance uses the configuration defined here.
 */
export const DefaultDataGridOptions = {
    disableColumnFilter: true,
    disableRowSelectionOnClick: true,
    pageSizeOptions: [
        DefaultDataGridPageSize,
        DefaultDataGridPageSize * 2.5,
        DefaultDataGridPageSize * 5,
        DefaultDataGridPageSize * 10,
    ],
    slotProps: {
        columnsPanel: {
            sx: dataGridColumnsPanelMenuStyles,
        },
        toolbar: {
            showQuickFilter: false,
        },
    },
    slots: { toolbar: GridToolbar },
};

// check if the current row is a data row or a footer row
export const isDataRow = (params: GridRenderCellParams) => Object.keys(params.row).length > 0;
